@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .p-block {
        @apply
        py-6
        md:py-10
        lg:py-16
        px-6
        sm:px-12
        lg:px-24
        2xl:px-48
    }
}

@layer components {
    .heading-1 {
        @apply text-5xl font-bold leading-[1.5] tracking-normal text-gray-900;
    }

    .heading-2 {
        @apply heading-1 text-3xl;
    }

    .heading-3 {
        @apply heading-1 text-2xl;
    }

    .heading-4 {
        @apply heading-1 text-lg;
    }

    .heading-5 {
        @apply heading-1 text-base;
    }

    .paragraph {
      @apply text-lg font-medium leading-tight tracking-normal text-gray-500;
    }

    .caption {
        @apply paragraph text-base;
    }

    .responsive-heading-1 {
        @apply lg:heading-1 md:heading-2 sm:heading-3 heading-3;
    }

    .responsive-heading-2 {
        @apply lg:heading-2 md:heading-3 sm:heading-4 heading-4;
    }

    .responsive-heading-3 {
        @apply lg:heading-3 md:heading-4 sm:heading-5 heading-5;
    }

    .responsive-paragraph {
        @apply lg:paragraph caption;
    }

    .button {
        @apply rounded-md h-[50px] bg-primary text-white shadow-lg md:hover:shadow-primary/40 text-center caption font-bold flex justify-center items-center px-6;
        
        /* disabled */
        @apply disabled:bg-slate-200 disabled:cursor-not-allowed disabled:shadow-none disabled:text-slate-500 disabled:border-slate-300;
    }

    .button-s {
        @apply button h-[42px]
    }

    .outlined-button {
        @apply button bg-transparent text-primary border-primary border-2 shadow-md md:hover:shadow-primary/40
    }

    .outlined-button-s {
        @apply outlined-button h-[42px]
    }
    
    .tag {
        @apply py-3 px-4 bg-appRose/10 text-appRose text-center;
    }
  }

#root {
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}
  
* {
    box-sizing: border-box;
}
  
html,
body {
    overflow-x: hidden;
    font-family: theme(fontFamily.manrope);
}

body {
    background-color: theme(colors.slate.100);
}

input[type="number"],
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  /* -moz-appearance: textfield; */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

h1 {
    @apply heading-1
}

h2 {
    @apply heading-2
}

h3 {
    @apply heading-3
}

h4 {
    @apply heading-4
}

h5 {
    @apply heading-5
}

p {
    @apply paragraph
}

button {
    @apply button
}